<template>
  <div class="home">
    <div class="image">
      <van-image
        width="100px"
        style="padding:20px;"
        height="100px"
        fit="contain"
        :src="isAdopt ? 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_success.png' : 'https://forexmaster.oss-cn-beijing.aliyuncs.com/openAccount/image/account_processing.png'" />
    </div>
    <div class="theme-color" v-if="form.oa_state === 0">
      <div v-if="form.net_state <= 2">网上资料审批中</div>
      <div v-if="form.net_state === 3">网上资料审批通过</div>
      <div v-if="form.net_state === 4">网上资料审批退回</div>
      <div v-if="!isMajorLocal">
        <div v-if="form.mail_state <= 2">邮寄材料审批中</div>
        <div v-if="form.mail_state === 3">邮寄材料审批通过 </div>
        <div v-if="form.mail_state === 4">邮寄材料审批退回</div>
      </div>
    </div>
    <div class="wraper theme-color" v-if="form.oa_state === 0">
      <div v-if="showA" style="margin-top: 20px;">
        我们正在认真审批您的资料，审批后我们将短信通知，请留意！
      </div>
      <div v-if="showB" style="margin-top: 20px;">
        请确认往我司账户转账不少于1万的港币或等值美元(<span class="link" @click="handleShowAccount">收款账户</span>)。
      </div>
      <div v-if="showC && !isMajorLocal" style="margin-top: 20px;">
        {{form.mail_step_info}}
      </div>
      <div v-if="showD && !isMajorLocal" style="margin-top: 20px;">
          <div v-if="form.auth_type === '香港/合资格银行'">
            <span>确认待邮寄材料无误后，将材料邮寄到我司。</span><br/>
            <span>邮寄材料：专业投资者证明原件</span><br/>
            <span>收件地址：香港铜锣湾礼顿道77号礼顿中心10楼1013 & 1015室</span><br/>
          </div>
          <div v-else>
            <span>完成线下见证，确认待邮寄材料无误后，将材料邮寄到我司。</span><br/>
            <span>邮寄材料：开户申请签字材料（稍后发送至您的邮箱：{{email}}，请下载打印并签字）、
              身份证件复印件、住址证明、银行证明、专业投资者证明（仅限专业投资者）</span><br/>
            <span>收件地址：香港铜锣湾礼顿道77号礼顿中心10楼1013 & 1015室</span><br/>
          </div>
      </div>
      <div v-if="showE" style="margin-top: 20px;">
        <van-steps class="step theme-color-bg" active="-1" direction="vertical">
          <van-step v-for="item in form.net_step_info" :key="item.type">
            {{typeList[item.type - 1]}}
            <template #inactive-icon>
              <div class="circle right" v-if="item.approveStatus === 1">
                <van-icon name="success" /></div>
              <div class="circle" v-else><van-icon name="cross" /></div>
            </template>
            <div style="margin-top: 10px;">{{item.comment}}</div>
          </van-step>
        </van-steps>
      </div>
      <div v-if="showE">
        <div style="width: 100%;text-align: center;margin-top: 20px;">
          <van-button type="primary" style="width: 80%;margin: auto;" @click="handleGoChoose">
            立即完善网上资料
          </van-button>
        </div>
      </div>
      <div v-else>
        <div style="width: 100%;text-align: center;margin-top: 20px;">
          <van-button type="primary" style="width: 80%;margin: auto;" @click="handleGoCheck">
            查看当前有效资料
          </van-button>
        </div>
      </div>
    </div>
    <div v-else-if="form.oa_state === 1">
      <p class="theme-color">恭喜，开户成功！</p>
      <p class="theme-color">{{form.approve_comment}}</p>
      <div v-if="form.isda_type" style="width: 100%;text-align: center;margin-top: 20px;">
        <van-button type="primary" style="width: 80%;margin: auto;" @click="handleGoIsda">
          {{ form.isda_state === 0 ? 'TRS收益互换衍生品服务( 仅限专业投资者 )' : 'TRS收益互换衍生品服务查询' }}
        </van-button>
      </div>
      <div
        v-if="addInfo && (addInfo.account_type === '证券保证金账户' || addInfo.major_invester !== '是')"
        style="width: 100%;text-align: center;margin-top: 20px;"
      >
        <van-button style="width: 80%;margin: auto;" @click="handleAddAccount">
          补充开户
        </van-button>
      </div>
      <div
        v-if="showYearlyRiskBtn"
        style="width: 100%;text-align: center;margin-top: 20px;"
      >
        <van-button style="width: 80%;margin: auto;" @click="handleRiskUpdate">
          风险评估更新
        </van-button>
      </div>
      <div style="width: 100%;text-align: center;margin-top: 20px;">
        <van-button style="width: 80%;margin: auto;" @click="handleGoCheck">
          查看当前有效资料
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Image as VanImage, Button, Steps, Step, Icon, Dialog,
} from 'vant';
import { useStore } from 'vuex';
import { onMounted, ref } from 'vue';
import httpApi from '../../utils/httpApi';
import httpAddApi from '../../utils/httpAddApi';

const alertMsg = `
注意：
1.必须用认证身份所绑定的银行卡转账不少于1万的港币或等值美元；
2.若未及时上传汇款凭证，后续请将凭证发送到customer-service@beevestsec.com，以便加快核实。

港币账户（中文）
银行名称： 中国银行（香港）有限公司
银行地址： 香港花园道1号中国银行大厦
SWIFT码： BKCHHKHH
户口名称： 蜂投证券有限公司
账户号码： 012-884-0-010359-5
附注：XXX开户（如：张三开户）

港币账户（英文）
银行名称： Bank of China (Hong Kong) Ltd
银行地址： Bank of China Tower, 1 Garden Road, Hong Kong
SWIFT码： BKCHHKHH
户口名称： BEEVEST SECURITIES LIMITED (CLIENTS' A/C)
账户号码： 012-884-0-010359-5
附注：XXX account opening (e.g. Peter account opening)

美元账户（中文）
银行名称： 中国银行(香港)有限公司
银行地址： 香港花园道1号中国银行大厦
SWIFT码： BKCHHKHH
户口名称： 蜂投证券有限公司
账户号码： 012-884-0-801729-2
附注：XXX开户（如：张三开户）

美元账户（英文）
银行名称： Bank of China (Hong Kong) Ltd
银行地址： Bank of China Tower, 1 Garden Road, Hong Kong
SWIFT码： BKCHHKHH
户口名称： BEEVEST SECURITIES LIMITED (CLIENTS' A/C)
账户号码： 012-884-0-801729-2
附注：XXX account opening (e.g. Peter account opening)
`;

export default {
  name: 'Home',
  components: {
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [Icon.name]: Icon,
  },
  data() {
    return {
      form: {},
      isAdopt: '',
      isMajorLocal: false,
      typeList: ['个人资料', '衍生产品认知', '专业投资者评估', '投资风险取向', '风险披露', '上传材料'],
    };
  },
  computed: {
    //
    showA() {
      const { net_state } = this.form;
      if (net_state <= 2) return true;
      return false;
    },
    showB() {
      const { net_state } = this.form;
      if (net_state !== 3 && this.form.auth_type === '香港/合资格银行') return true;
      return false;
    },
    showC() {
      const { mail_state } = this.form;
      if (mail_state === 4) return true;
      return false;
    },
    showD() {
      const { mail_state } = this.form;
      if (mail_state !== 3) return true;
      return false;
    },
    showE() {
      const { net_state } = this.form;
      if (net_state === 4) return true;
      return false;
    },
    yearlyRiskState() {
      return this.$store.state.user.yearlyRiskState;
    },
    showYearlyRiskBtn() {
      const y = this.yearlyRiskState;
      // -1-不可风险更新，0-可进行风险更新，1-风险更新已提交，2-风险更新审批中，4-风险更新退回，3-风险更新通过
      return (y === 0 || y === 1 || y === 2 || y === 4);
    },
  },
  async mounted() {
    const result = await httpApi.getApproveStatus();
    if (!result) return;
    this.form = result.data;
    const isAdopt = (this.form.net_state === 3) && (this.form.mail_state === 3);
    this.isAdopt = isAdopt;
    this.isMajorLocal = (this.form.auth_type === '香港/合资格银行' && this.form.major_invester === '否');
    if (this.form.oa_state === 1 && this.form.isda_type) {
      httpApi.getIsdaState();
    }
  },
  methods: {
    async handleAddAccount() {
      this.$store.commit('setIsAddAccount', true);
      await this.$api.submitLog({ type: 'addOpenAccountLogin' });
      if (this.addInfo) {
        const { addOpenAccountType, account_type } = this.addInfo;
        // 已提交默认为审批中....
        if (
          this.addInfo.isAddOpenAccountInfo === '是'
          && (addOpenAccountType === 2 || addOpenAccountType === 1 || addOpenAccountType === 4)
        ) {
          // 查询界面
          this.$router.push({ path: '/search/addAccount' });
        } else if (account_type === '证券保证金账户') {
          // 选择账户界面
          this.$router.push({ path: '/choose/index' });
        } else {
          // 衍生产品认知
          this.$router.push({ path: '/assess/index' });
        }
      }
    },
    async handleRiskUpdate() {
      if (this.yearlyRiskState === 0) {
        this.$router.push({ path: '/riskOrientation/step_one' });
        this.$store.commit('setIsYearlyRisk', true);
      } else {
        this.$router.push({ path: '/search/riskUpdate' });
      }
    },
    getAeIouData: async () => {},
    handleGoCheck() {
      this.$router.push({ path: '/check' });
    },
    handleGoChoose() {
      this.$store.commit('setIsAddAccount', false);
      this.$router.push({ path: '/choose/index' });
    },
    handleGoIsda() {
      const {
        cookiePhone, cookieExpires, isDark, platform,
      } = this.$store.state.user;
      const aft = (isDark && platform === 'APP' ? '&platform=AppDark' : '');
      if (this.form.isda_state === 0) {
        window.location.replace(`${this.$util.getIsdaHostInKH()}/isda/aggrement?c=${cookiePhone}&e=${cookieExpires}${aft}`);
      } else {
        window.location.replace(`${this.$util.getIsdaHostInKH()}/isda/search?c=${cookiePhone}&e=${cookieExpires}${aft}`);
      }
    },
    handleShowAccount() {
      Dialog.alert({
        title: '收款账户',
        messageAlign: 'left',
        message: alertMsg,
        confirmButtonColor: 'rgba(0,0,0,0.6)',
        confirmButtonText: '我知道了',
      });
    },
  },
  setup() {
    const email = ref('');
    const addInfo = ref('');
    onMounted(async () => {
      const store = useStore();
      store.commit('setTitleValue', '查询');
      store.commit('setStepsValue', ['填写资料(1/5)', '风险认知', '提交资料']);
      store.commit('setStepsShow', false);
      const email_res = await httpApi.getOpenAccountInfo();
      if (email_res) email.value = email_res.data.email;
      const addAccountInfo = await httpAddApi.getOpenAccountInfo();
      if (addAccountInfo) addInfo.value = addAccountInfo;
    });
    return {
      email,
      addInfo,
    };
  },
};
</script>

<style lang="less">
  .message {
    padding: @space-0;
    text-align: left;
    div {
      margin-bottom: @space-1;
    }
  }
  .step {
    background-color: transparent;
    text-align: left;
    margin-left: calc(@space-0 * 2);
  }
  .van-step {
    padding-left: @space-1;
  }
  .circle {
    border: 1px solid red;
    border-radius: 50%;
    text-align: center;
    width: 16px;
    height: 16px;
    .van-icon {
      width: 16px;
      height: 16px;
      line-height: 16px;
      color: red;
    }
  }
  .right {
    border: 1px solid green;
    .van-icon {
      color: green;
    }
  }
  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .home {
    padding-left: @space-0;
    padding-right: @space-0;
  }
  .wraper {
    text-align: left ;
  }
</style>
